import classNames from "classnames";
import React, { useState } from "react";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import Transition from "../../../../../../../../../common/Transition/Transition";
import TagPopupWrapper from "../../TaskPopups/TagPopup/TagPopupWrapper";
import TagItem from "./TagItem/TagItem";
import styles from './tasktags.module.css';

export default function TaskTags(props) {

  const [isOpenTagPopup, setIsOpenTagPopup] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const handleClickClosePopup = () => {
    setIsOpenTagPopup(false);
  }

  return (
    <div className={classNames('flex', styles.cardTags)}>
      <div className={classNames('flex', 'tagsWrapper')}>
        {
          props.tags.length > 0 ?
            props.tags.map(item =>
              <TagItem
                key={item.id}
                name={item.name}
                id={item.id}
                color={item.color}
                taskId={props.taskId}
                readOnly={props.readOnly}
              />
            )
            :
            <span>{props.readOnly ? 'Меток нет' : 'Добавить метки'}</span>
        }
      </div>
      {
        !props.readOnly &&
        <button
          onClick={() => setIsOpenTagPopup(true)}
          className={classNames('iconBtn', styles.btn)}
        >
          <AddIcon />
        </button>
      }
      <Transition open={isOpenTagPopup}>
        <TagPopupWrapper
          taskId={props.taskId}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
          boardId={props.boardId}
        />
      </Transition>
    </div>
  )
}
