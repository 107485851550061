import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '../../../../../../../../svgIcons/AddIcon';
import ArchiveIcon from '../../../../../../../../svgIcons/ArchiveIcon';
import CopyIcon from '../../../../../../../../svgIcons/CopyIcon';
import DeleteIcon from '../../../../../../../../svgIcons/DeleteIcon';
import DotsIcon from '../../../../../../../../svgIcons/DotsIcon';
import EditSmallIcon from '../../../../../../../../svgIcons/EditSmallIcon';
import FilterIcon from '../../../../../../../../svgIcons/FilterIcon';
import MoveIcon from '../../../../../../../../svgIcons/MoveIcon';
import SettingsRoundIcon from '../../../../../../../../svgIcons/SettingsRoundIcon';
import SortIcon from '../../../../../../../../svgIcons/SortIcon';
import { generateKey } from '../../../../../../../../utils/generateKey';
import ConfirmWindow from '../../../../../../../common/ConfirmWindow/ConfirmWindow';
import BoardControls from '../../../../ProjectHeader/BoardControls/BoardControls';
import styles from './columncontrols.module.css';
import { socket } from '../../../../../../../../API/socket';
import ColumnMovePopup from '../../ColumnPopups/ColumnMovePopup/ColumnMovePopup';
import ColumnStatusPopup from '../../ColumnPopups/ColumnStatusPopup/ColumnStatusPopup';
import ColumnSortPopup from '../../ColumnPopups/ColumnSortPopup/ColumnSortPopup';
import ColumnFilterPopup from '../../ColumnPopups/ColumnFilterPopup/ColumnFilterPopup';
import { createPortal } from 'react-dom';
import { selectProjectInfo } from '../../../../../../../../redux/ProjectPage/projectItemSelector';
import { useMemo } from 'react';
import Transition from '../../../../../../../common/Transition/Transition';

export default function ColumnControls(props) {

  const ref = useRef(null)
  const refControls = useRef(null)

  const projectInfo = useSelector(selectProjectInfo);

  const [transformY, setTransformY] = useState(0);
  const [transformX, setTransformX] = useState(0);

  const [isOpenControls, setIsOpenControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isOpenMovePopup, setIsOpenMovePopup] = useState(false);
  const [isOpenStatusPopup, setIsOpenStatusPopup] = useState(false);
  const [isOpenSortPopup, setIsOpenSortPopup] = useState(false);
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false);
  const [actionKey, setActionKey] = useState('');

  const handleClickOpenConfirm = (key) => {
    setActionKey(key)
    setIsOpenConfirmPopup(true)
    document.body.classList.add('modal-show');
  }

  const handleClickClosePopup = () => {
    isOpenConfirmPopup && setIsOpenConfirmPopup(false);
    isOpenMovePopup && setIsOpenMovePopup(false);
    isOpenStatusPopup && setIsOpenStatusPopup(false);
    isOpenSortPopup && setIsOpenSortPopup(false);
    isOpenFilterPopup && setIsOpenFilterPopup(false);
    document.body.classList.remove('modal-show');
    setActionKey('')
  }

  const handleClickOpenMove = (key) => {
    setActionKey(key);
    setIsOpenMovePopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenStatus = () => {
    setIsOpenStatusPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenSort = () => {
    setIsOpenSortPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenFilter = () => {
    setIsOpenFilterPopup(true);
    document.body.classList.add('modal-show');
  }

  const handleDeleteColumn = () => {
    actionKey === 'deleteColumn'
      ?
      socket.emit('deleteColumn', JSON.stringify(props.colId))
      :
      socket.emit('deleteAllTasks', { columnId: props.colId })

    handleClickClosePopup()
  }

  const handleArchiveColumn = () => {
    actionKey === 'archiveColumn'
      ?
      socket.emit('ArchiveColumn',
        {
          columnId: props.colId,
          archived: true //или false
        })
      :
      socket.emit('ArchiveAllTasks', { columnId: props.colId })

    handleClickClosePopup()
  }

  const handleDuplicate = () => {
    socket.emit('CopyColumn',
      {
        "columnId": props.colId,
        "boardId": props.boardId,
        "action": "duplicate"
      })
  }

  const handleChangeColor = (color) => {
    socket.emit('ChangeColor',
      {
        columnId: props.colId,
        color: color
      })
  }

  const controlsList = [
    {
      icon: <EditSmallIcon />,
      name: 'Переименовать',
      callBack: () => props.setIsEditName(true),
      id: generateKey(),
    },
    {
      icon: <SettingsRoundIcon />,
      name: 'Статус колонки',
      callBack: handleClickOpenStatus,
      id: generateKey(),
      key: 'copy'
    },
    // {
    //   icon: <SortIcon />,
    //   name: 'Сортировать',
    //   callBack: handleClickOpenSort,
    //   id: generateKey(),
    //   key: 'move'
    // },
    // {
    //   icon: <FilterIcon />,
    //   name: 'Фильтр',
    //   callBack: handleClickOpenFilter,
    //   id: generateKey(),
    // },
    {
      icon: <CopyIcon />,
      name: 'Копировать в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'copy'
    },
    {
      icon: <MoveIcon />,
      name: 'Переместить в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'move'
    },
    {
      icon: <CopyIcon />,
      name: 'Дублировать',
      callBack: handleDuplicate,
      id: generateKey(),
    },
    {
      icon: <ArchiveIcon />,
      name: 'Архивировать колонку',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'archiveColumn'
    },
    {
      icon: <ArchiveIcon />,
      name: 'Архивировать все задачи',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'archiveAllTasks'
    },
    {
      icon: <DeleteIcon />,
      name: 'Удалить колонку',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'deleteColumn'
    },
    {
      icon: <DeleteIcon />,
      name: 'Удалить все задачи',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'deleteAllTasks'
    },
    {
      pref: 'colors',
      icon: '',
      name: 'Цвет колонки',
      colorList: [
        {
          name: '#CD5C5C',
        },
        {
          name: '#FFA07A',
        },
        {
          name: '#FFB6C1',
        },
        {
          name: '#DB7093',
        },
        {
          name: '#FF7F50',
        },
        {
          name: '#FF6347',
        },
        {
          name: '#FFE4B5',
        },
        {
          name: '#EEE8AA',
        },
        {
          name: '#D2691E',
        },
        {
          name: '#90EE90',
        },
        {
          name: '#87CEEB',
        },
        {
          name: '#C0C0C0',
        },
      ],
      callBack: (color) => handleChangeColor(color),
      addColor: (color) => handleChangeColor(color),
      id: generateKey(),
    },
  ]

  const [controls, setControls] = useState(null)

  useMemo(() => {
    setControls(controlsList)
  }, [])

  const winHeight = window.innerHeight;
  const winWidth = window.innerWidth;

  useEffect(() => {
    const contY = refControls.current?.getBoundingClientRect().y;
    const contHeight = refControls.current?.getBoundingClientRect().height;

    const contX = refControls.current?.getBoundingClientRect().x;
    const contWidth = refControls.current?.getBoundingClientRect().width;

    (contY + contHeight) > winHeight ? setTransformY(contY + contHeight - winHeight + 10) : setTransformY(0);
    (contX + contWidth) > winWidth ? setTransformX(contX + contWidth - winWidth + 20) : setTransformX(0);

    return () => { }

  }, [isOpenControls])

  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmBtnText, setConfirmBtnText] = useState('');

  useEffect(() => {
    actionKey === 'move' ?
      setConfirmTitle('Переместить колонку?') :
      actionKey === 'copy' ?
        setConfirmTitle('Копировать колонку?') :
        actionKey === 'deleteColumn' ?
          setConfirmTitle('Удалить колонку?') :
          actionKey === 'deleteAllTasks' ?
            setConfirmTitle('Удалить все задачи?') :
            actionKey === 'archiveColumn' ?
              setConfirmTitle('Заархивировать колонку?') :
              actionKey === 'archiveAllTasks' ?
                setConfirmTitle('Заархивировать все задачи?') : setConfirmTitle(prev => prev)

    actionKey === 'move' ?
      setConfirmBtnText('Переместить') :
      actionKey === 'copy' ?
        setConfirmBtnText('Копировать') :
        actionKey === 'archiveColumn' || actionKey === 'archiveAllTasks' ?
          setConfirmBtnText('Подтвердить') :
          actionKey === 'deleteColumn' || actionKey === 'deleteAllTasks' ?
            setConfirmBtnText('Удалить') : setConfirmBtnText(prev => prev)
  }, [actionKey])

  return (
    <div
      className={classNames('flex', 'iconBtnGroup', styles.columnControls)}
      ref={ref}
    >
      <button
        onClick={() => {
          props.setFocusTask(true)
          props.setIsOpenAddTask(true)
        }}
        className={classNames('flex', 'iconBtn')}
      >
        <AddIcon />
      </button>
      <button
        onClick={() => setIsOpenControls(true)}
        className={classNames('flex', 'iconBtn', 'dotsBtn')}
      >
        <DotsIcon />
      </button>
      {
        isOpenControls &&
        createPortal((
          <div
            ref={refControls}
            className={classNames('absolutePortal', 'not-dnd', styles.controls)}
            style={{
              top: `${(ref.current?.getBoundingClientRect().y || 0) + ref.current?.getBoundingClientRect().height + window.scrollY + 5}px`,
              left: `${(ref.current?.getBoundingClientRect().x || 0) + 20}px`,
              transform: `translateY(${-transformY}px) translateX(${-transformX}px)`
            }}
          >
            <BoardControls
              handleClickClose={setIsOpenControls}
              controlsList={controls}
              columnPos={true}
              currentColor={props.currentColor}
              handleChangeColor={handleChangeColor}
              setChangedColor={props.setChangedColor}
            />
          </div>
        ), document.getElementById('modal_root'))
      }
      <Transition open={isOpenConfirmPopup}>
        <ConfirmWindow
          title={confirmTitle}
          btnText={confirmBtnText}
          onDelete={(actionKey === 'deleteColumn' || actionKey === 'deleteAllTasks') ? handleDeleteColumn : handleArchiveColumn}
          closeDelete={handleClickClosePopup}
        />
      </Transition>
      <Transition open={isOpenMovePopup}>
        <ColumnMovePopup
          keyAct={actionKey === 'move' ? 'move' : 'copy'}
          title={confirmTitle}
          btnText={confirmBtnText}
          handleClickClose={handleClickClosePopup}
          colId={props.colId}
        />
      </Transition>
      <Transition open={isOpenStatusPopup}>
        <ColumnStatusPopup
          handleClickClose={handleClickClosePopup}
          colId={props.colId}
          projectId={projectInfo.id}
        />
      </Transition>
      <Transition open={isOpenSortPopup}>
        <ColumnSortPopup
          handleClickClose={handleClickClosePopup}
        />
      </Transition>
      <Transition open={isOpenFilterPopup}>
        <ColumnFilterPopup
          projectUsers={projectInfo.workers}
          handleClickClose={handleClickClosePopup}
        />
      </Transition>
    </div>
  )
}
