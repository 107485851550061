import classNames from 'classnames';
import React, { useState } from 'react';
import { socket } from '../../../../../../../../../API/socket';
import DeleteIcon from '../../../../../../../../../svgIcons/DeleteIcon';
import EditIcon from '../../../../../../../../../svgIcons/EditIcon';
import ConfirmWindow from '../../../../../../../../common/ConfirmWindow/ConfirmWindow';
import styles from './subtask.module.css';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { hex2rgb } from '../../../../../../../../../utils/hex2rgb';

export default function SubTask(props) {

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false)
  const [editName, setEditName] = useState(false)
  const [subTaskName, setSubTaskName] = useState(props.item.name);

  const handleClickOpenConfirm = (e) => {
    e.stopPropagation()
    setIsOpenConfirmPopup(true);
    document.body.classList.remove('modal-show');
  }

  const handleClickClosePopup = () => {
    setIsOpenConfirmPopup(false);
    document.body.classList.remove('modal-show');
  }

  const handleDeleteSubTask = () => {
    socket.emit('DeleteSubTask',
      {
        'taskId': props.taskId,
        'subtaskId': props.item.id,
      });
    handleClickClosePopup();
  }

  const onDone = (value) => {
    socket.emit('ChangeSubTaskDone',
      {
        'taskId': props.taskId,
        'subtaskId': props.item.id,
        'done': value
      })
  }

  const onSubmit = () => {
    setEditName(false);
    console.log(subTaskName);
    socket.emit('ChangeSubTaskName',
      {
        'taskId': props.taskId,
        'subtaskId': props.item.id,
        'name': subTaskName
      })
    setShowMenu(false);
  }

  const [isDone, setIsDone] = useState(props.item.done)

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.item.id,
    data: {
      type: "Subtask",
      subtask: props.item
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={classNames('contentBlock', styles.subtask, props.overlay && styles.notPseudo, isDragging && styles.shadow)}
      onClick={e => e.stopPropagation()}
      ref={setNodeRef}
      style={{ style }}
      {...attributes}
      {...listeners}
    >
      {
        !editName ?
          <div
            className={classNames('flex', 'checkboxContainer', styles.subTaskCheckContainer)}
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
            <input
              type="checkbox"
              id={props.item.id}
              name='checkList'
              checked={isDone}
              onChange={(e) => {
                onDone(e.target.checked)
                console.log(
                  {
                    'taskId': props.taskId,
                    'subtaskId': props.item.id,
                    'done': e.target.checked
                  }
                )
                setIsDone(e.target.checked)
              }}
            />
            <label
              htmlFor={props.item.id}
            >
              {subTaskName}
            </label>
            {
              showMenu &&
              <div className={classNames('flex', 'iconBtnGroup', styles.subTaskBtnGroup)}>
                <button
                  className={classNames('flex', 'iconBtn')}
                  onClick={() => setEditName(true)}
                >
                  <EditIcon />
                </button>
                <button
                  className={classNames('flex', 'iconBtn')}
                  onClick={handleClickOpenConfirm}
                >
                  <DeleteIcon />
                </button>
              </div>
            }
          </div>
          :
          <div
            onClick={(e) => e.stopPropagation()}
            className={classNames('areaWrapper', styles.textWrapper)}
          >
            <p className={'hiddenText'}>
              {subTaskName} <br />
              текст
            </p>
            <textarea
              autoFocus={true}
              onFocus={(e) => e.target.setSelectionRange(subTaskName.length, subTaskName.length)}
              className={classNames('input', 'resizeArea')}
              name="task"
              placeholder='Введите название'
              defaultValue={subTaskName}
              onChange={(e) => setSubTaskName(e.target.value)}
              onBlur={onSubmit}
            >
            </textarea>
          </div>
      }
      {/* {
        isOpenConfirmPopup && */}
        <div
          onClick={(e) => e.stopPropagation()}
        >
          <ConfirmWindow
            open={isOpenConfirmPopup}
            title={'Удалить подзадачу?'}
            onDelete={handleDeleteSubTask}
            closeDelete={handleClickClosePopup}
          />
        </div>
      {/* } */}
    </div>
  );
}
