import classNames from "classnames";
import React, { useState } from "react";
import { socket } from "../../../../../../../../../../API/socket";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import DeleteIcon from "../../../../../../../../../../svgIcons/DeleteIcon";
import ConfirmWindow from "../../../../../../../../../common/ConfirmWindow/ConfirmWindow";
import Transition from "../../../../../../../../../common/Transition/Transition";
import CheckItemPopupWrapper from "../../TaskPopups/CheckItemPopup/CheckItemPopupWrapper";
import styles from './taskchecklist.module.css';

export default function TaskCheckList(props) {

  const allList = props.checkList?.items?.length;
  const checkList = props.checkList?.items?.filter(item => item.checked).length;

  const percent = Math.round((checkList / allList) * 100)

  const [isOpenCheckItemPopup, setIsOpenCheckItemPopup] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);

  const handleDeleteChecklist = () => {
    socket.emit('ClearChecklist', {
      "taskId": props.taskId,
    })
    setIsOpenConfirmPopup(false)
  }
  const handleAddChecklistItem = (data) => {
    socket.emit('AddChecklistItem', {
      "taskId": props.taskId,
      "name": data.name,
      "checked": false
    })
  }
  const handleDeleteItem = (id) => {
    socket.emit('DeleteChecklistItem', {
      "itemId": id
    })
  }
  const handleCheckedItem = (id, checked) => {
    socket.emit('EditChecklistItem', {
      "itemId": id,
      "checked": checked
    })
  }


  return (
    <div className={styles.checkListBlock}>
      <div className={classNames('flex', 'blockTitleWrapper', styles.checkTitleWrap)}>
        <h2>Чек-лист {props.checkList?.name}</h2>
        {
          !props.readOnly &&
          <div className={classNames('flex', 'iconBtnGroup')}>

            <button
              onClick={() => setIsOpenCheckItemPopup(true)}
              className={classNames('flex', 'iconBtn')}
            >
              <AddIcon />
            </button>
            <button
              onClick={() => setIsOpenConfirmPopup(true)}
              className={classNames('flex', 'iconBtn')}
            >
              <DeleteIcon />
            </button>
          </div>
        }
      </div>

      {props.checkList?.items?.length > 0 &&
        <div>
          <div className={classNames('flex', styles.indicatorWrapper)}>
            <p className={styles.percent}>
              {percent}%
            </p>
            <div className={styles.indicatorFull}>
              <div
                className={styles.indicatorPercent}
                style={{ 'width': `${percent}%` }}
              >
              </div>
            </div>
          </div>
          <div>
            {props.checkList?.items?.map(item =>
              <div
                key={item.id}
                className={classNames('flex')}
              >
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input
                    checked={item.checked}
                    type="checkbox"
                    id={item.id}
                    onChange={(e) => !props.readOnly && handleCheckedItem(item.id, e.target.checked)}
                  />
                  <label htmlFor={item.id}>{item.name}</label>
                </div>
                {
                  !props.readOnly &&
                  <button
                    onClick={() => handleDeleteItem(item.id)}
                    className={classNames('flex', 'iconBtn')}
                  >
                    <DeleteIcon />
                  </button>
                }
              </div>
            )}
          </div>
        </div>
      }
      <Transition open={isOpenCheckItemPopup}>
        <CheckItemPopupWrapper
          insideParent={true}
          handleClickClose={() => setIsOpenCheckItemPopup(false)}
          handleAddChecklistItem={handleAddChecklistItem}
        />
      </Transition>
      <Transition open={isOpenConfirmPopup}>
        <ConfirmWindow
          insideParent={true}
          title={'Удалить чек-лист?'}
          onDelete={handleDeleteChecklist}
          closeDelete={() => setIsOpenConfirmPopup(false)}
        />
      </Transition>
    </div>
  )
}
