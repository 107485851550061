import React from 'react';
import styles from './fileimagecard.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import DotsIcon from '../../../../../svgIcons/DotsIcon';
import FileCardMenu from '../FileCardMenu/FileCardMenu';
import ImgPopup from '../../../ImgPopup/ImgPopup';
import DeleteIcon from '../../../../../svgIcons/DeleteIcon';
import Transition from '../../../Transition/Transition';

export default function FileImageCard(props) {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenFullImg, setIsOpenFullImg] = useState(false);
  const [link, setLink] = useState('');

  const handleClickOpenImg = () => {
    setIsOpenFullImg(true)
    document.body.classList.add('modal-show');
  }
  const handleClickCloseImg = (e) => {
    setIsOpenFullImg(false)
    document.body.classList.remove('modal-show');
  }

  return (
    <div
      className={classNames('flex', 'imageBox', styles.image, props.editFile ? styles.editImg : styles.openImg)}
      onClick={() => {
        !props.editFile && setLink(props.item.file)
        !props.editFile && handleClickOpenImg()
      }}
    >
      <img
        src={props.item.file}
      />
      {
        !props.withoutMenu ?
          <button
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setIsOpenMenu(true)
            }}
            className={classNames('flex', 'iconBtn', 'dotsBtn', styles.btn)}
          >
            <DotsIcon />
            {
              isOpenMenu &&
              <FileCardMenu
                item={props.item.id}
                files={props.files}
                fileMenu={props.fileMenu || props.fileMenuImg}
                setIsOpenMenu={setIsOpenMenu}
              />
            }
          </button>
          :
          props.editFile ?
            <button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                props.handleDeleteFile({ arr: props.files, item: props.item })
              }}
              className={classNames('flex', 'iconBtn', 'dotsBtn', styles.btn)}
            >
              <DeleteIcon />
            </button>
            :
            null
      }

      <Transition open={isOpenFullImg}>
        <ImgPopup
          file={link}
          handleClickClose={handleClickCloseImg}
          insideParent={props.insideParent}
        />
      </Transition>
    </div>
  );
}







