import React from 'react';
import styles from './preloader.module.css';
import preloader from '../../../assets/images/preloader.gif';
import classNames from 'classnames';

export default function Preloader(props) {
  return (
    <div className={classNames(styles.preloaderBox)}>
      <img src={preloader} alt='preloader' />
    </div>
  )
}
