import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './confirmwindow.module.css';
import classNames from "classnames";
import { useOutsideClick } from "../../hooks/useOutsideClick";

export default function ConfirmWindow(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.closeDelete);

  return (
    !props.insideParent ?
      createPortal((

        <div
          className={classNames('flex', 'popup', styles.confirmPopup)}
          onClick={e => e.stopPropagation()}
        >
          <div className={classNames('flex', 'popupWindow', styles.confirmPopupWindow)} ref={ref}>
            <h2 className={styles.deleteHeader}>{props.title}</h2>
            <div>
              <button
                className={classNames('btn', styles.deleteBtn)}
                onClick={props.onDelete}
              >
                {props.btnText || 'Удалить'}
              </button>
              <button
                className={classNames('btn', 'btnTransparent', styles.addBtn)}
                onClick={props.closeDelete}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      ), document.getElementById('modal_root'))
      :
      <div className={classNames('flex', 'popup', styles.confirmPopup)}>
        <div className={classNames('flex', 'popupWindow', styles.confirmPopupWindow)} ref={ref}>
          <h2 className={styles.deleteHeader}>{props.title}</h2>
          <div>
            <button
              className={classNames('btn', styles.deleteBtn)}
              onClick={props.onDelete}
            >
              {props.btnText || 'Удалить'}
            </button>
            <button
              className={classNames('btn', 'btnTransparent', styles.addBtn)}
              onClick={props.closeDelete}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
  )
}
