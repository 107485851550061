import React from "react";
import { CSSTransition } from 'react-transition-group';

export default function Transition({children, open}) {
  return (
    <CSSTransition
      in={open}
      classNames='popup'
      unmountOnExit
      timeout={300}
    >
      {children}
    </CSSTransition>
  )
}
