import './App.css';
import './assets/styles/common.css';
import './assets/styles/table.css';
import './assets/styles/form.css';
import './assets/styles/popup.css';
import './assets/styles/reactSelect.css';
import './assets/styles/scrollbar.css';
import './assets/styles/fullCalendar.css';
import './assets/styles/datePicker.css';
import './assets/styles/quill.css';
import './assets/styles/emoji.css';
import './assets/styles/bigCalendar.css';
import './assets/styles/transitions.css';
import 'react-quill/dist/quill.snow.css';
import SitePage from './Layout/SitePage/SitePage';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './utils/privateRoute';
import LoginContainer from './Layout/Login/LoginContainer';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/*'
          element={
            <PrivateRoute>
              <SitePage />
            </PrivateRoute>
          }
        />
        <Route path='/login' element={<LoginContainer />} />
      </Routes>
    </div>
  );
}

export default App;
