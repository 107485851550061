import { boardItemAPI, projectsListAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_COLUMNS = 'SET_COLUMNS';
const SET_TASK_COMMENT = 'SET_TASK_COMMENT';
const SET_TASK_CHECKLIST = 'SET_TASK_CHECKLIST';
const GET_PROJECTS_FOR_BOARD = 'GET_PROJECTS_FOR_BOARD';
const CHANGE_COLUMN = 'CHANGE_COLUMN';
const CHANGE_TASK = 'CHANGE_TASK';
const SET_CHECKLISTS = 'SET_CHECKLISTS';

const SET_LABELS = 'SET_LABELS';
const CREATE_LABEL = 'CREATE_LABEL';
const DELETE_LABEL = 'DELETE_LABEL';
const EDIT_LABEL = 'EDIT_LABEL';

const SET_COLUMN_STATUSES = 'SET_COLUMN_STATUSES';
const CREATE_COLUMN_STATUS = 'CREATE_COLUMN_STATUS';
const DELETE_COLUMN_STATUS = 'DELETE_COLUMN_STATUS';
const EDIT_COLUMN_STATUS = 'EDIT_COLUMN_STATUS';

let initialState = {
  columns: {
    columns: [],
  },
  projectsForBoard: [],
  labels: [],
  statuses: [],
  checklists: [],
  color: null,
  isFetching: false
};

export const columnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      }
    case SET_TASK_COMMENT:
      return {
        ...state,
        columns: {
          ...state.columns,
          columns:
            state.columns.columns
              .map(col => {
                if (col.id === action.action.colId) {
                  return {
                    ...col,
                    tasks: col.tasks
                      .map(task => {
                        if (task.id === action.action.taskId) {
                          return {
                            ...task,
                            comments: action.action.comments
                          }
                        } else {
                          return task
                        }
                      })
                  }
                } else {
                  return col
                }
              })
        }
      }
    case SET_TASK_CHECKLIST:
      return {
        ...state,
        columns: {
          ...state.columns,
          columns:
            state.columns.columns
              .map(col => {
                if (col.id === action.action.colId) {
                  return {
                    ...col,
                    tasks: col.tasks
                      .map(task => {
                        if (task.id === action.action.taskId) {
                          return {
                            ...task,
                            checklists: action.action.checklists
                          }
                        } else {
                          return task
                        }
                      })
                  }
                } else {
                  return col
                }
              })
        }
      }
    case CHANGE_COLUMN:
      return {
        ...state,
        columns: {
          ...state.columns,
          columns:
            state.columns.columns
              .map(col => {
                if (col.id === action.action.colId) {
                  return action.action.column
                } else {
                  return col
                }
              })
        }
      }
    case CHANGE_TASK:
      return {
        ...state,
        columns: {
          ...state.columns,
          columns:
            state.columns.columns
              .map(col => {
                if (col.id === action.action.colId) {
                  return {
                    ...col,
                    tasks: col.tasks
                      .map(task => {
                        if (task.id === action.action.task.id) {
                          return action.action.task
                        } else {
                          return task
                        }
                      })
                  }
                } else {
                  return col
                }
              })
        }
      }
    case GET_PROJECTS_FOR_BOARD:
      return {
        ...state,
        projectsForBoard: [...action.projectsForBoard],
      }
    case SET_CHECKLISTS:
      return {
        ...state,
        checklists: [...action.checklists],
      }

    case SET_LABELS:
      return {
        ...state,
        labels: [...action.labels],
      }
    case CREATE_LABEL:
      return {
        ...state,
        labels: [...state.labels, action.newItem],
      }
    case DELETE_LABEL:
      return {
        ...state,
        labels: state.labels.filter((el) => el.id !== action.id),
      }
    case EDIT_LABEL:
      return {
        ...state,
        labels: mapObj(state.labels, action.itemId, 'id', action.newObj),
      }

    case SET_COLUMN_STATUSES:
      return {
        ...state,
        statuses: [...action.statuses],
      }
    case CREATE_COLUMN_STATUS:
      return {
        ...state,
        statuses: [...state.statuses, action.newItem],
      }
    case DELETE_COLUMN_STATUS:
      return {
        ...state,
        statuses: state.statuses.filter((el) => el.id !== action.id),
      }
    case EDIT_COLUMN_STATUS:
      return {
        ...state,
        statuses: mapObj(state.statuses, action.itemId, 'id', action.newObj),
      }
    default: return { ...state };
  }
}

export const setBoardColumns = (columns) => ({ type: SET_COLUMNS, columns });
export const setTaskComments = ({ comments: comments, colId: colId, taskId: taskId }) => ({ type: SET_TASK_COMMENT, action: { comments: comments, colId: colId, taskId: taskId } });
export const setTaskChecklist = ({ checklists: checklists, colId: colId, taskId: taskId }) => ({ type: SET_TASK_CHECKLIST, action: { checklists: checklists, colId: colId, taskId: taskId } });
export const setColumnChange = ({ column: column, colId: colId }) => ({ type: CHANGE_COLUMN, action: { column: column, colId: colId } });
export const setTaskChange = ({ task: task, colId: colId }) => ({ type: CHANGE_TASK, action: { task: task, colId: colId } });

const setProjectsForBoard = (projectsForBoard) => ({ type: GET_PROJECTS_FOR_BOARD, projectsForBoard });
const setChecklist = (checklists) => ({ type: SET_CHECKLISTS, checklists });

const setLabels = (labels) => ({ type: SET_LABELS, labels });
const setCreateLabel = (newItem) => ({ type: CREATE_LABEL, newItem });
const setEditLabel = (itemId, newObj) => ({ type: EDIT_LABEL, itemId, newObj });
const setDeleteLabel = (id) => ({ type: DELETE_LABEL, id });

const setStatuses = (statuses) => ({ type: SET_COLUMN_STATUSES, statuses });
const setCreateColumnStatus = (newItem) => ({ type: CREATE_COLUMN_STATUS, newItem });
const setEditColumnStatus = (itemId, newObj) => ({ type: EDIT_COLUMN_STATUS, itemId, newObj });
const setDeleteColumnStatus = (id) => ({ type: DELETE_COLUMN_STATUS, id });

export const getProjectsForBoard = () => async (dispatch) => {
  await projectsListAPI.getProjectListForBoards()
    .then(response => dispatch(setProjectsForBoard(response.data.projects)))
    .catch(err => console.log(err))
}

export const getLabels = (id) => async (dispatch) => {
  await boardItemAPI.getBoardLabels(id)
    .then(response => dispatch(setLabels(response.data)))
    .catch(err => console.log(err))
}
export const createLabel = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await boardItemAPI.createBoardLabel({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setCreateLabel(response.data)))
    .catch(err => console.log(err))
}
export const editLabel = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await boardItemAPI.editBoardLabel({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setEditLabel(id, response.data)))
    .catch(err => console.log(err))
}
export const deleteLabel = (id) => async (dispatch) => {
  await boardItemAPI.deleteBoardLabel(id)
    .then(response => dispatch(setDeleteLabel(id)))
    .catch(err => console.log(err))
}

export const getChecklists = (id) => async (dispatch) => {
  await boardItemAPI.getBoardChecklist(id)
    .then(response => dispatch(setChecklist(response.data)))
    .catch(err => console.log(err))
}

export const getColumnStatuses = (id) => async (dispatch) => {
  await boardItemAPI.getColumnStatuses(id)
    .then(response => dispatch(setStatuses(response.data)))
    .catch(err => console.log(err))
}
export const createColumnStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await boardItemAPI.createColumnStatus({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setCreateColumnStatus(response.data)))
    .catch(err => console.log(err))
}
export const editColumnStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await boardItemAPI.editColumnStatus({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setEditColumnStatus(id, response.data)))
    .catch(err => console.log(err))
}
export const deleteColumnStatus = (id) => async (dispatch) => {
  await boardItemAPI.deleteColumnStatus(id)
    .then(response => dispatch(setDeleteColumnStatus(id)))
    .catch(err => console.log(err))
}
